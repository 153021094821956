import { captureException } from '@sentry/react';
import { noop } from 'lodash-es';
import type { Dict, Mixpanel } from 'mixpanel-browser';
import type { FunctionComponent, PropsWithChildren } from 'react';
import { use, createContext, useEffect, useMemo } from 'react';
import { environment } from '../../env.ts';
import { useOptionalCurrentUser } from '../current-user-context.tsx';

const { mixpanelToken } = window.wirechunk;

// The Mixpanel script can be blocked by tracker blockers based on the file name.
// If the module fails to load, allow the app to be rendered anyway.
let mixpanelInstance: Mixpanel | null = null;
if (mixpanelToken) {
  const mixpanelInstanceName = 'site';
  try {
    const mb = await import('mixpanel-browser');
    mixpanelInstance = mb.default.init(
      mixpanelToken,
      {
        ...(environment === 'production' ? {} : { debug: true }),
      },
      mixpanelInstanceName,
    );
  } catch (error) {
    captureException(error);
  }
}

type ProductAnalyticsContext = {
  track: (event: string, properties?: Dict | null, sendImmediately?: boolean) => void;
  reset: () => void;
};

const ProductAnalyticsContext = createContext<ProductAnalyticsContext>({
  track: noop,
  reset: noop,
});

export const ProductAnalyticsContextProvider: FunctionComponent<PropsWithChildren> = ({
  children,
}) => {
  const { user } = useOptionalCurrentUser();

  const orgId = user?.orgId || null;
  const value = useMemo<ProductAnalyticsContext>(
    () => ({
      track: (event, properties = {}, sendImmediately) => {
        mixpanelInstance?.track(
          event,
          {
            orgId,
            ...properties,
          },
          sendImmediately ? { send_immediately: true } : undefined,
        );
      },
      reset: () => mixpanelInstance?.reset(),
    }),
    [orgId],
  );

  const userId = user?.id;
  useEffect(() => {
    if (userId) {
      mixpanelInstance?.identify(userId);
    }
  }, [userId]);

  return <ProductAnalyticsContext value={value}>{children}</ProductAnalyticsContext>;
};

export const useProductAnalytics = (): ProductAnalyticsContext => use(ProductAnalyticsContext);
