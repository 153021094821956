import { Checkbox, Grid } from '@radix-ui/themes';
import type { LayoutProps, MarginProps } from '@radix-ui/themes/props';
import type { FunctionComponent } from 'react';
import { InputNotice } from '../InputNotice/InputNotice.tsx';
import { Label } from '../label/label.tsx';

type CheckboxWithLabelProps = LayoutProps &
  MarginProps & {
    id: string;
    label: string;
    labelClassName?: string;
    checked: boolean;
    onChange: (event: { checked: boolean }) => void;
    inputNotice?: string;
  };

export const CheckboxWithLabel: FunctionComponent<CheckboxWithLabelProps> = ({
  id,
  label,
  labelClassName,
  checked,
  onChange,
  inputNotice,
  ...props
}) => (
  <Grid columns="max-content 1fr" align="center" gapX="2" gapY="1" {...props}>
    <Checkbox
      id={id}
      checked={checked}
      onCheckedChange={(checked) => {
        onChange({ checked: checked === true });
      }}
    />
    <Label htmlFor={id} mb="0" className={labelClassName}>
      {label}
    </Label>
    {inputNotice && (
      <InputNotice className="column-start-2 -mt-px font-normal">{inputNotice}</InputNotice>
    )}
  </Grid>
);
