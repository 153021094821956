import { TextArea } from '@radix-ui/themes';
import { componentClassName } from '@wirechunk/lib/mixer/component-class-name.ts';
import { inputLabelClassName } from '@wirechunk/lib/mixer/styles.ts';
import type { LessonNotesFieldComponent } from '@wirechunk/lib/mixer/types/components.ts';
import type { FunctionComponent } from 'react';
import { useId } from 'react';
import { FormField } from '../../form-field/form-field.tsx';
import { Label } from '../../label/label.tsx';
import { useLessonUserContext } from '../course/lesson-user-context.ts';

export const LessonNotesField: FunctionComponent<LessonNotesFieldComponent> = (props) => {
  const lessonUserContext = useLessonUserContext();
  const inputId = useId();

  if (!lessonUserContext) {
    return null;
  }

  return (
    <FormField className={componentClassName(props)}>
      <Label htmlFor={inputId} className={inputLabelClassName}>
        {props.label || 'Notes'}
      </Label>
      <TextArea
        id={inputId}
        className="w-full"
        value={lessonUserContext.notes}
        onChange={(e) => {
          lessonUserContext.setNotes(e.target.value);
        }}
      />
    </FormField>
  );
};
