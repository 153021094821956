import { useSuspenseQuery } from '@apollo/client';
import type { DesignComponent } from '@wirechunk/lib/mixer/types/components.ts';
import type { ContextData } from '@wirechunk/schemas/context-data/context-data';
import type { FunctionComponent } from 'react';
import { use, useMemo } from 'react';
import { PageContext, ViewMode } from '../../../contexts/PageContext/page-context.tsx';
import { PropsContext } from '../../../contexts/props-context.ts';
import { ParseAndRenderComponents } from '../../ParseAndRenderComponents.tsx';
import { CustomComponentDocument, CustomComponentPreviewDocument } from './queries.generated.ts';

// This constant is declared here to stay referentially consistent.
const defaultCustomProps: ContextData = {};

type DesignComponentWithId = DesignComponent & {
  customComponentId: string;
};

const DesignBody: FunctionComponent<DesignComponentWithId> = ({ customComponentId, ...props }) => {
  const { data } = useSuspenseQuery(CustomComponentDocument, {
    variables: { id: customComponentId },
  });

  // TODO: Merge with existing props.
  return (
    <PropsContext value={props.customProps ?? defaultCustomProps}>
      <ParseAndRenderComponents componentsJSON={data.component.components} />
    </PropsContext>
  );
};

const DesignPreviewBody: FunctionComponent<DesignComponentWithId> = ({ customComponentId }) => {
  const { data } = useSuspenseQuery(CustomComponentPreviewDocument, {
    variables: { id: customComponentId },
  });
  const previewProps = useMemo(
    () =>
      data.component.previewProps ? (JSON.parse(data.component.previewProps) as ContextData) : {},
    [data],
  );

  // TODO: Merge with existing props.
  return (
    <PropsContext value={previewProps}>
      <ParseAndRenderComponents componentsJSON={data.component.components} />
    </PropsContext>
  );
};

export const Design: FunctionComponent<DesignComponent> = (props) => {
  const { viewMode } = use(PageContext);

  const customComponentId = props.customComponentId || props.designId;
  if (customComponentId) {
    return viewMode !== ViewMode.Preview ? (
      <DesignBody {...props} customComponentId={customComponentId} />
    ) : (
      <DesignPreviewBody {...props} customComponentId={customComponentId} />
    );
  }

  return null;
};
