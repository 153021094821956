import type { BoxProps } from '@radix-ui/themes';
import { Grid, RadioGroup } from '@radix-ui/themes';
import type { LayoutProps, MarginProps } from '@radix-ui/themes/props';
import { clsx } from 'clsx';
import type { FunctionComponent, PropsWithChildren } from 'react';
import { FormField } from '../form-field/form-field.tsx';
import { InputNotice } from '../InputNotice/InputNotice.tsx';
import { Label } from '../label/label.tsx';

type RadioGroupContainerProps = BoxProps &
  Required<Pick<RadioGroup.RootProps, 'value' | 'onValueChange'>> & {
    legend?: string;
    innerWrapperClassName?: string;
  };

export const RadioGroupContainer: FunctionComponent<RadioGroupContainerProps> = ({
  legend,
  value,
  onValueChange,
  innerWrapperClassName,
  children,
  ...props
}) => (
  <FormField mx="0" p="0" {...props} className={clsx('border-none', props.className)} asChild>
    <fieldset>
      {legend && <Label legend>{legend}</Label>}
      <RadioGroup.Root
        value={value}
        onValueChange={onValueChange}
        className={innerWrapperClassName}
      >
        {children}
      </RadioGroup.Root>
    </fieldset>
  </FormField>
);

type RadioGroupItemWithLabelProps = PropsWithChildren<
  LayoutProps &
    MarginProps & {
      // Used for the label.
      id: string;
      value: string;
      inputNotice?: string;
      labelClassName?: string;
    }
>;

export const RadioGroupItemWithLabel: FunctionComponent<RadioGroupItemWithLabelProps> = ({
  id,
  value,
  children,
  labelClassName,
  inputNotice,
  ...props
}) => (
  <Grid columns="max-content 1fr" align="center" gapX="2" gapY="1" {...props}>
    <RadioGroup.Item id={id} value={value} />
    <Label htmlFor={id} mb="0" className={labelClassName}>
      {children}
    </Label>
    {inputNotice && (
      <InputNotice className="column-start-2 -mt-px font-normal">{inputNotice}</InputNotice>
    )}
  </Grid>
);
