import type { FetchResult } from '@apollo/client';
import { useMutation } from '@apollo/client';
import { useState } from 'react';
import type { ErrorHandler } from '../useErrorHandler.tsx';
import type { SendVerificationEmailMutation } from './mutations.generated.ts';
import { SendVerificationEmailDocument } from './mutations.generated.ts';

type SendVerificationEmail = {
  sendVerificationEmail: () => Promise<FetchResult<SendVerificationEmailMutation>>;
  isSending: boolean;
  verificationEmailSent: boolean;
  resetVerificationEmailSent: () => void;
};

export const useSendVerificationEmail = (
  onError: ErrorHandler['onError'],
  onSent?: () => void,
): SendVerificationEmail => {
  const [verificationEmailSent, setVerificationEmailSent] = useState(false);
  const [sendVerificationEmail, { loading }] = useMutation(SendVerificationEmailDocument, {
    onError,
    onCompleted: (data) => {
      if (data.sendVerificationEmail) {
        setVerificationEmailSent(true);
        onSent?.();
      }
    },
  });

  return {
    sendVerificationEmail,
    isSending: loading,
    verificationEmailSent,
    resetVerificationEmailSent: () => {
      setVerificationEmailSent(false);
    },
  };
};
