import type { FetchResult } from '@apollo/client';
import { useMutation } from '@apollo/client';
import { useCallback } from 'react';
import { useCurrentUser } from '../../../../contexts/current-user-context.tsx';
import type { ErrorHandler } from '../../../../hooks/useErrorHandler.tsx';
import type { EditSequenceUserMutation } from '../../../admin/pages/Users/UserDetails/mutations.generated.ts';
import { EditSequenceUserDocument } from '../../../admin/pages/Users/UserDetails/mutations.generated.ts';

type EditUserPlanLive = {
  editSequenceUser: (
    sequenceId: string,
    stageBlueprintId: string,
  ) => Promise<FetchResult<EditSequenceUserMutation>>;
  editSequenceUserLoading: boolean;
};

// The "Live" part of the name refers to the way the mutation result selection is meant for the mutation to be
// used by someone editing their own UserPlan that they're on.
export const useEditSequenceUser = (onError: ErrorHandler['onError']): EditUserPlanLive => {
  const { user } = useCurrentUser();
  const [edit, { loading: editSequenceUserLoading }] = useMutation(EditSequenceUserDocument, {
    onError,
    onCompleted: (data) => {
      if (data.editSequenceUser.__typename !== 'EditSequenceUserSuccessResult') {
        onError(data.editSequenceUser);
      }
    },
  });

  const editSequenceUser = useCallback<EditUserPlanLive['editSequenceUser']>(
    (sequenceId, stageBlueprintId) =>
      edit({
        variables: {
          input: {
            userId: user.id,
            sequenceId,
            stageId: stageBlueprintId,
          },
        },
      }),
    [edit, user.id],
  );

  return {
    editSequenceUser,
    editSequenceUserLoading,
  };
};
