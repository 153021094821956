import { useApolloClient, useMutation } from '@apollo/client';
import { Button, DropdownMenu, IconButton, Text, Badge } from '@radix-ui/themes';
import { setUser as setSentryUser } from '@sentry/browser';
import { clsx } from 'clsx';
import { PrimeIcons } from 'primereact/api';
import { Avatar } from 'primereact/avatar';
import type { FunctionComponent } from 'react';
import { Fragment } from 'react';
import { Link, useRevalidator } from 'react-router';
import { useCurrentUser } from '../../../contexts/current-user-context.tsx';
import { useProductAnalytics } from '../../../contexts/ProductAnalyticsContext/ProductAnalyticsContext.tsx';
import {
  SessionStatus,
  useSessionStatusContext,
} from '../../../contexts/SessionStatusContext/session-status-context.tsx';
import { useToast } from '../../../contexts/ToastContext.tsx';
import { useErrorHandler } from '../../../hooks/useErrorHandler.tsx';
import { useSendVerificationEmail } from '../../../hooks/useSendVerificationEmail/useSendVerificationEmail.ts';
import { signOutMessenger } from '../../../util/zendesk.ts';
import { WirechunkLogo } from '../../admin/wirechunk-logo.tsx';
import { SiteLogoStandardStyle } from '../../site-logo-standard-style.tsx';
import { SignOutDocument } from './mutations.generated.ts';
import styles from './toolbar.module.css';

const { admin } = window.wirechunk;

export const toolbarLogoStyle = {
  height: '38px',
  width: 'auto',
};

const userProfileAlertBadge = (
  <Badge
    color="red"
    variant="solid"
    size="2"
    radius="full"
    className="absolute"
    style={{
      width: '19px',
      height: '19px',
      top: '-1px',
      right: '-1px',
      opacity: 1,
      justifyContent: 'center',
    }}
  >
    1
  </Badge>
);

export type ToolbarProps = {
  className?: string;
  standalone?: boolean;
  onShowSidebar?: () => void;
  LeftElements?: FunctionComponent;
};

export const Toolbar: FunctionComponent<ToolbarProps> = ({
  className,
  standalone = false,
  onShowSidebar,
  LeftElements,
}) => {
  // eslint-disable-next-line @typescript-eslint/unbound-method
  const { revalidate } = useRevalidator();
  const { toastSuccess } = useToast();
  const { user } = useCurrentUser();
  const sessionStatus = useSessionStatusContext();
  const apiClient = useApolloClient();
  const { reset: resetAnalyticsIdentity } = useProductAnalytics();
  const { onErrorToast } = useErrorHandler();
  const [signOut, { loading: signOutLoading }] = useMutation(SignOutDocument, {
    onError: onErrorToast,
    onCompleted: () => {
      void (async () => {
        await apiClient.resetStore();
        // This invokes the route's loader so that the page
        void revalidate();
        resetAnalyticsIdentity();
        signOutMessenger();
        setSentryUser(null);
      })();
    },
  });
  const { sendVerificationEmail, isSending, verificationEmailSent, resetVerificationEmailSent } =
    useSendVerificationEmail(onErrorToast, () => {
      toastSuccess('Please check your inbox for the verification email.', 'Email sent');
    });

  return (
    <header className={clsx(className, styles.toolbar, standalone && styles.standalone)}>
      <div className="flex align-items-center flex-grow-1">
        {onShowSidebar && (
          <button
            type="button"
            className={`${styles.sidebarOpenButton} border-none p-button-text`}
            onClick={onShowSidebar}
          >
            <i className={PrimeIcons.BARS} />
          </button>
        )}
        <div className="flex align-items-center mr-2 md:mr-3">
          {admin ? <WirechunkLogo /> : <SiteLogoStandardStyle />}
        </div>
        {LeftElements && <LeftElements />}
      </div>
      {signOutLoading ? (
        <div className="text-sm text-color-muted">Signing out...</div>
      ) : (
        <Fragment>
          <DropdownMenu.Root
            onOpenChange={(open) => {
              if (!open) {
                resetVerificationEmailSent();
              }
            }}
          >
            <DropdownMenu.Trigger>
              <IconButton
                type="button"
                variant="ghost"
                color="gray"
                radius="full"
                size="1"
                className="relative"
              >
                <Avatar
                  icon="pi pi-user"
                  // icon={user.photoUrl ? undefined : 'pi pi-user'}
                  // image={user.photoUrl || undefined}
                  shape="circle"
                />
                {!user.emailVerified && userProfileAlertBadge}
              </IconButton>
            </DropdownMenu.Trigger>
            <DropdownMenu.Content>
              <DropdownMenu.Label className="h-max">
                <div className="flex flex-column align-items-center text-center pt-2">
                  <div>
                    <Avatar
                      icon="pi pi-user"
                      // icon={user.photoUrl ? undefined : 'pi pi-user'}
                      // image={user.photoUrl || undefined}
                      size="xlarge"
                    />
                  </div>
                  <Text weight="medium" mt="2" className="text-color-body">
                    {user.displayName}
                  </Text>
                  <Text mt="1" mb="3" size="2" className="text-color-body">
                    {user.email.length > 28 ? `${user.email.slice(0, 26)}...` : user.email}
                  </Text>
                  {!user.emailVerified && (
                    <Fragment>
                      <div className="mb-1 text-sm font-italic">Your email is not verified yet</div>
                      <Button
                        mb="4"
                        onClick={() => {
                          void sendVerificationEmail();
                        }}
                        disabled={isSending || verificationEmailSent}
                      >
                        Send verification email
                      </Button>
                    </Fragment>
                  )}
                </div>
              </DropdownMenu.Label>
              <DropdownMenu.Item asChild>
                <Link to="/profile">{admin ? 'Profile' : 'My profile'}</Link>
              </DropdownMenu.Item>
              {admin ? (
                <Fragment>
                  <DropdownMenu.Item asChild>
                    <Link to="/api-tokens">API Tokens</Link>
                  </DropdownMenu.Item>
                  <DropdownMenu.Item asChild>
                    <Link to="/org-settings">Org settings</Link>
                  </DropdownMenu.Item>
                </Fragment>
              ) : (
                user.role === 'OrganizationOwner' && (
                  <DropdownMenu.Item asChild>
                    <Link to="/billing">Billing</Link>
                  </DropdownMenu.Item>
                )
              )}
              <DropdownMenu.Item
                onClick={() => {
                  // This needs to be updated here so that queries created in components that require authentication
                  // don't get refetched.
                  sessionStatus.setStatus(SessionStatus.SignedOut);
                  void signOut();
                }}
              >
                Sign out
              </DropdownMenu.Item>
            </DropdownMenu.Content>
          </DropdownMenu.Root>
        </Fragment>
      )}
    </header>
  );
};
