import type { MeFragment } from '@wirechunk/lib/shared-queries/me-fragment.generated.ts';
import type { FunctionComponent, PropsWithChildren } from 'react';
import { createContext, use, useMemo } from 'react';

export type CurrentUser = MeFragment;

type CurrentUserContext = {
  user: CurrentUser | null;
  loadingUser: boolean;
};

type RequiredCurrentUserContext = {
  user: CurrentUser;
  loadingUser: boolean;
};

const isRequiredCurrentUserContext = (
  context: CurrentUserContext,
): context is RequiredCurrentUserContext => context.user !== null;

const CurrentUserContext = createContext<CurrentUserContext>({
  user: null,
  loadingUser: false,
});

type CurrentUserProviderProps = PropsWithChildren<CurrentUserContext>;

export const CurrentUserProvider: FunctionComponent<CurrentUserProviderProps> = ({
  user,
  loadingUser,
  children,
}) => {
  const currentUserContext = useMemo<CurrentUserContext>(
    (): CurrentUserContext => ({
      user,
      loadingUser,
    }),
    [user, loadingUser],
  );

  return <CurrentUserContext value={currentUserContext}>{children}</CurrentUserContext>;
};

export const useCurrentUser = (): RequiredCurrentUserContext => {
  const currentUserContext = use(CurrentUserContext);

  if (isRequiredCurrentUserContext(currentUserContext)) {
    return currentUserContext;
  }

  throw new Error('There is no user authenticated.');
};

export const useOptionalCurrentUser = (): CurrentUserContext => use(CurrentUserContext);
