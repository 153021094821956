import { Flex } from '@radix-ui/themes';
import { booleanOrDefaultFalse } from '@wirechunk/lib/booleans.ts';
import { componentClassName } from '@wirechunk/lib/mixer/component-class-name.ts';
import { inputLabelClassName } from '@wirechunk/lib/mixer/styles.ts';
import type { BooleanInputComponent } from '@wirechunk/lib/mixer/types/components.ts';
import { MixerEventType } from '@wirechunk/lib/mixer/types/components.ts';
import type { ValidInputComponent } from '@wirechunk/lib/mixer/utils.ts';
import type { CheckboxChangeEvent } from 'primereact/checkbox';
import { Checkbox } from 'primereact/checkbox';
import type { FunctionComponent } from 'react';
import { useInputDataContext } from '../../../contexts/InputDataContext.tsx';
import { useInputId } from '../../../hooks/use-input-id.ts';
import type { MixerEventDetailForType } from '../../../util/mixer/events.ts';
import { mixerQualifiedEventType } from '../../../util/mixer/events.ts';
import { InputNotice, NoticeSeverity } from '../../InputNotice/InputNotice.tsx';
import { Label } from '../../label/label.tsx';
import { withValidInputComponent } from '../../mixer-hocs/with-valid-input-component.tsx';

export const GuardedBooleanInput: FunctionComponent<ValidInputComponent<BooleanInputComponent>> = (
  props,
) => {
  const { getValue, setValue, getValidationError } = useInputDataContext(props);
  const inputId = useInputId(props);

  const validationError = getValidationError(props);

  const inputValue = booleanOrDefaultFalse(getValue(props));

  const handleChange = (event: CheckboxChangeEvent) => {
    const value = !!event.checked;
    setValue(props, value);
    const mixerEvent = new CustomEvent<MixerEventDetailForType<MixerEventType.InputChange>>(
      mixerQualifiedEventType(MixerEventType.InputChange),
      {
        detail: {
          type: MixerEventType.InputChange,
          component: props,
          value,
        },
      },
    );
    document.dispatchEvent(mixerEvent);
  };

  return (
    <div className={componentClassName(props)}>
      <Flex align="center" gapX="2" gapY="1">
        <Checkbox inputId={inputId} checked={inputValue} onChange={handleChange} />
        {props.label && (
          <Label htmlFor={inputId} className={inputLabelClassName}>
            {props.label}
          </Label>
        )}
      </Flex>
      {validationError && (
        <InputNotice severity={NoticeSeverity.Error}>{validationError}</InputNotice>
      )}
    </div>
  );
};

export const BooleanInput = withValidInputComponent<BooleanInputComponent>(GuardedBooleanInput);
