import { Flex, RadioCards, RadioGroup } from '@radix-ui/themes';
import { componentClassName } from '@wirechunk/lib/mixer/component-class-name.ts';
import { inputLabelClassName } from '@wirechunk/lib/mixer/styles.ts';
import type { RadioGroupInputButtonComponent } from '@wirechunk/lib/mixer/types/components.ts';
import { DataSource } from '@wirechunk/lib/mixer/types/components.ts';
import { isString } from 'lodash-es';
import type { FunctionComponent } from 'react';
import { useId } from 'react';
import { usePropsContext } from '../../../contexts/props-context.ts';
import { useConditionalStylesAttribute } from '../../../hooks/use-conditional-styles-attribute.ts';
import { useMixerContextData } from '../../../hooks/use-mixer-context-data.tsx';
import { Label } from '../../label/label.tsx';
import { RenderMixerChildren } from '../../RenderMixerChildren.tsx';
import { useRadioGroupInputContext } from '../radio-group-input/radio-group-input-context.ts';

export const RadioGroupInputButton: FunctionComponent<RadioGroupInputButtonComponent> = (props) => {
  const propsContext = usePropsContext();
  const contextData = useMixerContextData();
  const radioGroupInputContext = useRadioGroupInputContext();
  const conditionalStylesAttr = useConditionalStylesAttribute(props, contextData);
  const id = useId();

  if (!radioGroupInputContext) {
    return null;
  }

  const value =
    props.value?.type === DataSource.Direct
      ? props.value.value
      : props.value?.name && propsContext[props.value.name];

  if (!isString(value)) {
    return null;
  }

  if (radioGroupInputContext.displayType === 'indicators') {
    return (
      <Flex gap="2" align="center" className={componentClassName(props)} {...conditionalStylesAttr}>
        <RadioGroup.Item id={id} value={value} />
        {props.childrenOrLabel === 'children' ? (
          <RenderMixerChildren>{props.children}</RenderMixerChildren>
        ) : (
          props.label && (
            <Label htmlFor={id} className={inputLabelClassName} mb="0">
              {props.label}
            </Label>
          )
        )}
      </Flex>
    );
  }

  return (
    <RadioCards.Item {...conditionalStylesAttr} className={componentClassName(props)} value={value}>
      <RenderMixerChildren>{props.children}</RenderMixerChildren>
    </RadioCards.Item>
  );
};
