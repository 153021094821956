import type { BoxProps } from '@radix-ui/themes';
import { Box } from '@radix-ui/themes';
import type { LayoutProps, MarginProps } from '@radix-ui/themes/props';
import { clsx } from 'clsx';
import type { FunctionComponent, PropsWithChildren, Ref } from 'react';
import styles from './label.module.css';

type LabelOrLegend =
  // Label
  | { legend?: false; htmlFor: string; ref?: Ref<HTMLLabelElement> }
  // Legend
  | { legend: true; htmlFor?: never; ref?: Ref<HTMLLegendElement> };

// Note className is added to the default class name.
type LabelProps = PropsWithChildren<LayoutProps & MarginProps> &
  Pick<BoxProps, 'className' | 'display'> &
  LabelOrLegend;

export const Label: FunctionComponent<LabelProps> = ({
  className,
  legend,
  htmlFor,
  children,
  ...props
}) => (
  // @ts-expect-error -- ref is correct because we are using the asChild prop.
  <Box
    {...props}
    // Default browser styling includes left padding.
    pl={props.pl ?? (legend ? '0' : undefined)}
    className={clsx(styles.label, legend && styles.legend, className)}
    asChild
  >
    {legend ? <legend>{children}</legend> : <label htmlFor={htmlFor}>{children}</label>}
  </Box>
);
