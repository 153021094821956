import { useQuery } from '@apollo/client';
import type { ComponentType, FunctionComponent } from 'react';
import { useCurrentUser } from '../../contexts/current-user-context.tsx';
import type { SiteContext } from '../../contexts/SiteContext/SiteContext.tsx';
import { useErrorHandler } from '../../hooks/useErrorHandler.tsx';
import { Spinner } from '../spinner/spinner.tsx';
import { OrganizationSiteContextDocument } from './queries.generated.ts';

type OrgSite = {
  site: SiteContext;
};

export const withOrgSite =
  <P,>(WrappedComponent: ComponentType<P & OrgSite>): FunctionComponent<P> =>
  (props: P) => {
    const { onError, ErrorMessage } = useErrorHandler();
    const { user } = useCurrentUser();
    const { data, loading } = useQuery(OrganizationSiteContextDocument, {
      onError,
      fetchPolicy: 'cache-and-network',
      variables: {
        organizationId: user.orgId,
      },
    });

    if (loading) {
      return <Spinner py="3" />;
    }

    const site = data?.organization.site;
    if (site) {
      return <WrappedComponent {...props} site={site} />;
    }

    return <ErrorMessage />;
  };
