import type { PlainTextComponent } from '@wirechunk/lib/mixer/types/components.ts';
import { DataSource } from '@wirechunk/lib/mixer/types/components.ts';
import { stringOrDefaultNull } from '@wirechunk/lib/strings.ts';
import type { FunctionComponent } from 'react';
import { use } from 'react';
import { PageContext, ViewMode } from '../../contexts/PageContext/page-context.tsx';
import { PropsContext } from '../../contexts/props-context.ts';
import { SiteContext } from '../../contexts/SiteContext/SiteContext.tsx';

export const PlainText: FunctionComponent<PlainTextComponent> = (props) => {
  if (!props.text) {
    return null;
  }
  if (props.text.type === DataSource.Prop) {
    const propsContext = use(PropsContext);
    return props.text.name ? stringOrDefaultNull(propsContext[props.text.name]) : null;
  }
  if (props.text.type === 'PageTitle') {
    const pageContext = use(PageContext);
    if (pageContext.viewMode === ViewMode.NotFound) {
      return null;
    }
    return pageContext.title;
  }
  if (props.text.type === 'SiteName') {
    const siteContext = use(SiteContext);
    return siteContext.name;
  }
  return props.text.text;
};
