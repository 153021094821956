import type { FunctionComponent, PropsWithChildren, ReactNode } from 'react';
import { useOptionalCurrentUser } from '../contexts/current-user-context.tsx';
import {
  SessionStatus,
  useSessionStatusContext,
} from '../contexts/SessionStatusContext/session-status-context.tsx';
import { AuthForm } from './AuthForm/AuthForm.tsx';

type AuthenticationGuardProps = PropsWithChildren<{
  logo: ReactNode;
  // Display input fields and the sign in button in a large variant.
  lg?: boolean;
}>;

export const AuthenticationGuard: FunctionComponent<AuthenticationGuardProps> = ({
  logo,
  lg,
  children,
}) => {
  const { user, loadingUser } = useOptionalCurrentUser();
  const sessionStatus = useSessionStatusContext();
  if (user && sessionStatus.status === SessionStatus.Active) {
    return children;
  }
  return <AuthForm logo={logo} loadingUser={loadingUser} lg={lg} />;
};
