import { useMutation } from '@apollo/client';
import type { ComponentSpec } from '@wirechunk/lib/mixer/types/components.ts';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import type { FunctionComponent } from 'react';
import { Fragment, useState } from 'react';
import { useOptionalCurrentUser } from '../../../contexts/current-user-context.tsx';
import { useErrorHandler } from '../../../hooks/useErrorHandler.tsx';
import { WirechunkLogo } from '../../admin/wirechunk-logo.tsx';
import { FormField } from '../../form-field/form-field.tsx';
import { Label } from '../../label/label.tsx';
import { SiteLogoStandardStyle } from '../../site-logo-standard-style.tsx';
import { RequestPasswordResetDocument } from './mutations.generated.ts';

const { admin } = window.wirechunk;

export const RequestPasswordReset: FunctionComponent<
  ComponentSpec<'RequestPasswordReset'>
> = () => {
  const { onError, clearMessages, ErrorMessage } = useErrorHandler();
  const { user } = useOptionalCurrentUser();
  const [requestPasswordReset, { loading }] = useMutation(RequestPasswordResetDocument, {
    onError,
  });
  const [email, setEmailAddress] = useState('');
  const [requestSent, setRequestSent] = useState(false);

  const onSubmit = () => {
    clearMessages();
    void requestPasswordReset({
      variables: { email },
      onCompleted: () => {
        setRequestSent(true);
      },
    });
  };

  return (
    <div className="h-screen flex justify-content-center align-items-center surface-ground">
      <div className="w-22rem max-body-width-contained bg-white p-4 border-1 border-round mb-2">
        <div className="flex align-items-center justify-content-center gap-2 mb-4">
          {admin ? <WirechunkLogo /> : <SiteLogoStandardStyle />}
        </div>
        <ErrorMessage />
        {user ? (
          <div className="text-center">
            You are already signed in.
            <br />({user.email})
          </div>
        ) : loading ? (
          <div className="text-center">
            <div className="font-medium mb-3">Sending your request now&hellip;</div>
            <i className="pi pi-spinner pi-spin text-xl text-color-primary" />
          </div>
        ) : requestSent ? (
          <Fragment>
            <p>Your password reset link has been sent out to you! Please check your inbox.</p>
          </Fragment>
        ) : (
          <Fragment>
            <h4 className="text-center">Reset your password</h4>
            <FormField>
              <Label htmlFor="emailAddress" className="text-left">
                Your email address
              </Label>
              <InputText
                id="emailAddress"
                className="w-full"
                value={email}
                onChange={(e) => {
                  setEmailAddress(e.target.value);
                }}
                onKeyUp={(e) => {
                  if (e.key === 'Enter') {
                    onSubmit();
                  }
                }}
              />
            </FormField>
            <Button
              className="mt-2 w-full"
              label="Send link"
              disabled={!email.trim()}
              onClick={onSubmit}
            />
          </Fragment>
        )}
      </div>
    </div>
  );
};
