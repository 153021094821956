import { Flex } from '@radix-ui/themes';
import { booleanOrDefaultFalse } from '@wirechunk/lib/booleans.ts';
import { componentClassName } from '@wirechunk/lib/mixer/component-class-name.ts';
import type { MultiSelectInputComponent } from '@wirechunk/lib/mixer/types/components.ts';
import type { ValidInputComponent } from '@wirechunk/lib/mixer/utils.ts';
import { SvgCheck } from '@wirechunk/material-symbols-react-400/20/outlined/check.tsx';
import { SvgClose } from '@wirechunk/material-symbols-react-400/20/outlined/close.tsx';
import { Button } from 'primereact/button';
import type { FunctionComponent } from 'react';
import { useState } from 'react';
import { useInputDataContext } from '../../../contexts/InputDataContext.tsx';
import { useInputId } from '../../../hooks/use-input-id.ts';
import { CheckboxWithLabel } from '../../checkbox-with-label/checkbox-with-label.tsx';
import { Label } from '../../label/label.tsx';
import { withValidInputComponent } from '../../mixer-hocs/with-valid-input-component.tsx';

enum CheckResult {
  None,
  Correct,
  Incorrect,
}

const GuardedMultiSelectInput: FunctionComponent<ValidInputComponent<MultiSelectInputComponent>> = (
  props,
) => {
  const { getValue, setValue } = useInputDataContext(props);
  const inputIdRoot = useInputId(props);
  const inputValue = booleanOrDefaultFalse(getValue(props));
  const question = props.question;
  const choices = props.choices ?? [];
  const answerIndices = props.answerIndices ?? [];

  const [selections, setSelections] = useState<number[]>([]);
  const [checkResult, setCheckResult] = useState<CheckResult>(CheckResult.None);

  const checkSubmission = () => {
    if (
      answerIndices.every((answerIndex) => selections.includes(answerIndex)) &&
      answerIndices.length === selections.length
    ) {
      setValue(props, true);
      setCheckResult(CheckResult.Correct);
    } else {
      setCheckResult(CheckResult.Incorrect);
    }
  };

  return (
    <div className={componentClassName(props)}>
      <Label legend>{question}</Label>
      <Flex direction="column" gap="2" mt="2">
        {choices.map((choice, index) => (
          <CheckboxWithLabel
            key={index}
            id={`${inputIdRoot}-${index}`}
            label={choice}
            labelClassName="font-normal"
            checked={selections.includes(index) || (inputValue && answerIndices.includes(index))}
            onChange={({ checked }) => {
              // Don't allow changes after a correct submission.
              if (!inputValue) {
                setCheckResult(CheckResult.None);
                setSelections(
                  checked ? [...selections, index] : selections.filter((s) => s !== index),
                );
              }
            }}
          />
        ))}
      </Flex>
      {inputValue ? (
        <div className="flex align-items-center gap-1 mt-2 text-green-9">
          <SvgCheck /> <span className="font-medium">Correct</span>
        </div>
      ) : (
        <div className="flex align-items-center gap-2 mt-3">
          <Button
            label="Submit"
            disabled={inputValue}
            className="w-fit p-button-sm"
            onClick={checkSubmission}
          />
          {checkResult === CheckResult.Incorrect && (
            <div className="flex align-items-center gap-1 text-red-9">
              <SvgClose /> <span className="font-medium">Not quite. Try again!</span>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export const QuizMultiSelectInput =
  withValidInputComponent<MultiSelectInputComponent>(GuardedMultiSelectInput);
