import { useCallback, useEffect, useState } from 'react';
import { useOptionalCurrentUser } from '../contexts/current-user-context.tsx';
import { useReadonlyInputDataContext } from '../contexts/InputDataContext.tsx';
import { usePropsContext } from '../contexts/props-context.ts';
import type { MixerContextData } from '../util/mixer/context-data.ts';
import { buildContextData } from '../util/mixer/context-data.ts';
import { useInterval } from './useInterval.ts';

export const useMixerContextData = (): MixerContextData => {
  const { user } = useOptionalCurrentUser();
  const { data: inputData } = useReadonlyInputDataContext();
  const propsContext = usePropsContext();
  const [contextData, setContextData] = useState<MixerContextData>(() =>
    buildContextData({
      user,
      inputData: inputData.visible,
      props: propsContext,
    }),
  );
  const refreshContextData = useCallback(() => {
    setContextData(
      buildContextData({
        user,
        inputData: inputData.visible,
        props: propsContext,
      }),
    );
  }, [user, inputData.visible, propsContext]);
  useInterval(refreshContextData, 30_000);
  useEffect(() => {
    setContextData(
      buildContextData({
        user,
        inputData: inputData.visible,
        props: propsContext,
      }),
    );
  }, [user, inputData.visible, propsContext]);

  return contextData;
};
