import { skipToken, useSuspenseQuery } from '@apollo/client';
import type { ProvidePropsComponent } from '@wirechunk/lib/mixer/types/components.ts';
import { DataSource } from '@wirechunk/lib/mixer/types/components.ts';
import type { ContextData, DataValue } from '@wirechunk/schemas/context-data/context-data';
import type { FunctionComponent } from 'react';
import { use, useMemo } from 'react';
import { useSearchParams } from 'react-router';
import { PropsContext } from '../../../contexts/props-context.ts';
import { useSiteContext } from '../../../contexts/SiteContext/SiteContext.tsx';
import { RenderMixerChildren } from '../../RenderMixerChildren.tsx';
import { MeCustomFieldsDocument, PublicSiteCustomFieldsDocument } from './queries.generated.ts';

export const ProvideProps: FunctionComponent<ProvidePropsComponent> = ({ props, children }) => {
  const [searchParams] = useSearchParams();
  const propsContext = use(PropsContext);
  const site = useSiteContext();
  const { data: siteCustomFieldsData } = useSuspenseQuery(
    PublicSiteCustomFieldsDocument,
    props?.some(({ source }) => source.type === 'CustomField' && source.object === 'Site')
      ? { variables: { siteId: site.id } }
      : skipToken,
  );
  const { data: meCustomFieldsData } = useSuspenseQuery(
    MeCustomFieldsDocument,
    props?.some(({ source }) => source.type === 'CustomField' && source.object === 'User')
      ? undefined
      : skipToken,
  );

  const siteCustomFieldValues = siteCustomFieldsData?.publicSite.custom.customFieldValues;
  const meCustomFieldValues = meCustomFieldsData?.me?.custom.customFieldValues;
  const overridePropsContext = useMemo(() => {
    if (!props) {
      return propsContext;
    }
    const newProps: ContextData = {};
    for (const { name, ...prop } of props) {
      if (name) {
        switch (prop.source.type) {
          case DataSource.Direct:
            if (prop.source.value) {
              const { value } = prop.source.value;
              newProps[name] = value;
            }
            break;
          case 'CustomField': {
            const { fieldName } = prop.source;
            if (fieldName) {
              switch (prop.source.object) {
                case 'Site': {
                  const value = siteCustomFieldValues?.find(
                    (field) => field.customField.name === fieldName,
                  )?.value;
                  if (value) {
                    // This assertion isn't accurate. The value could be a PlainDate/PlainDateTime/etc.
                    newProps[name] = JSON.parse(value) as DataValue;
                  }
                  break;
                }
                case 'User': {
                  const value = meCustomFieldValues?.find(
                    (field) => field.customField.name === fieldName,
                  )?.value;
                  if (value) {
                    // This assertion isn't accurate. The value could be a PlainDate/PlainDateTime/etc.
                    newProps[name] = JSON.parse(value) as DataValue;
                  }
                  break;
                }
                default:
                  break;
              }
            }
            break;
          }
          case 'QueryParameter':
            if (prop.source.parameter) {
              newProps[name] = searchParams.get(prop.source.parameter);
            }
        }
      }
    }
    return { ...propsContext, ...newProps };
  }, [props, propsContext, searchParams, siteCustomFieldValues, meCustomFieldValues]);

  return (
    <PropsContext value={overridePropsContext}>
      <RenderMixerChildren>{children}</RenderMixerChildren>
    </PropsContext>
  );
};
