import { useMutation, useQuery } from '@apollo/client';
import { Button, Text } from '@radix-ui/themes';
import { componentClassName } from '@wirechunk/lib/mixer/component-class-name.ts';
import type { ActiveOrgSitePageGuardComponent } from '@wirechunk/lib/mixer/types/components.ts';
import type { FunctionComponent } from 'react';
import { useCurrentUser } from '../../../contexts/current-user-context.tsx';
import { useErrorHandler } from '../../../hooks/useErrorHandler.tsx';
import { RenderMixerChildren } from '../../RenderMixerChildren.tsx';
import { Spinner } from '../../spinner/spinner.tsx';
import { withOrgSite } from '../../with-organization-site/with-org-site.tsx';
import { OrgSitePagesFromPageTemplateDocument } from '../org-site-page-link/queries.generated.ts';
import { CreatePageFromTemplateDocument } from '../org-site-pages/mutations.generated.ts';
import { OptionalIdFilterOperator } from '#api';

export const defaultPageMissingMessage = 'You do not have this page active yet.';

export const ActiveOrgSitePageGuard: FunctionComponent<ActiveOrgSitePageGuardComponent> =
  withOrgSite(({ site, pageTemplateId, ...props }) => {
    const { user } = useCurrentUser();
    const { onError, ErrorMessage } = useErrorHandler();
    const queryVariables = pageTemplateId
      ? {
          siteId: site.id,
          pageTemplateId: {
            operator: OptionalIdFilterOperator.Equals,
            value: [pageTemplateId],
          },
        }
      : undefined;
    const { data, error, loading } = useQuery(OrgSitePagesFromPageTemplateDocument, {
      fetchPolicy: 'cache-and-network',
      onError,
      ...(queryVariables ? { variables: queryVariables } : { skip: true }),
    });
    const [createPageFromTemplate, { loading: createPageFromTemplateLoading }] = useMutation(
      CreatePageFromTemplateDocument,
      {
        onError,
        update: (cache, { data }, { variables }) => {
          const page = data?.createPageFromTemplate;
          if (page && variables) {
            const orgSitePagesData = cache.readQuery({
              query: OrgSitePagesFromPageTemplateDocument,
              variables: queryVariables,
            });
            if (orgSitePagesData) {
              cache.writeQuery({
                query: OrgSitePagesFromPageTemplateDocument,
                variables: queryVariables,
                data: {
                  ...orgSitePagesData,
                  site: {
                    ...orgSitePagesData.site,
                    pages: {
                      __typename: 'PagesList',
                      pages: [page],
                    },
                  },
                },
              });
            }
          }
        },
      },
    );
    const page = data?.site.pages.pages[0];
    if (page || !pageTemplateId) {
      return <RenderMixerChildren>{props.children}</RenderMixerChildren>;
    }

    const userHasEditRole = user.role === 'OrganizationOwner';

    return (
      <div className={componentClassName(props)}>
        <ErrorMessage />
        {loading && <Spinner />}
        {data && !error && (
          <div>
            <Text as="p" weight="medium" mb="2">
              {props.pageMissingMessage || defaultPageMissingMessage}
              {!userHasEditRole && ' Please ask your administrator to activate it.'}
            </Text>
            {userHasEditRole && (
              <Button
                disabled={createPageFromTemplateLoading}
                onClick={() => {
                  void createPageFromTemplate({
                    variables: {
                      siteId: site.id,
                      templateId: pageTemplateId,
                    },
                  });
                }}
              >
                Activate
              </Button>
            )}
          </div>
        )}
      </div>
    );
  });
