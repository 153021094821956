import { ApolloProvider } from '@apollo/client';
import { DirectionProvider } from '@radix-ui/react-direction';
import * as TooltipPrimitive from '@radix-ui/react-tooltip';
import type { FunctionComponent, PropsWithChildren } from 'react';
import { lazy } from 'react';
import { RouterProvider } from 'react-router/dom';
import { apolloClient } from './apollo-client.ts';
import { Passthrough } from './components/passthrough.tsx';
import { ErrorBoundary } from './components/RootErrorBoundary/error-boundary.tsx';
import { SessionUserProvider } from './components/SessionUserProvider.tsx';
import { ThemeProvider } from './components/theme-provider.tsx';
import { ErrorCollectorContextProvider } from './contexts/error-collector-context.tsx';
import { ProductAnalyticsContextProvider } from './contexts/ProductAnalyticsContext/ProductAnalyticsContext.tsx';
import { SessionStatusContextProvider } from './contexts/SessionStatusContext/session-status-context.tsx';
import { ToastProvider } from './contexts/ToastContext.tsx';
import { router } from './router.tsx';

import './css/primeflex.css';
import 'primeicons/primeicons.css';
import '@radix-ui/themes/styles.css';
import './css/theme.css';
import './css/index.css';

const StripeElementsProvider: FunctionComponent<PropsWithChildren> = window.wirechunk.stripePK
  ? lazy(() => import('./components/StripeElementsProvider.tsx'))
  : Passthrough;

export const App: FunctionComponent = () => (
  <ErrorBoundary showDialog>
    <ThemeProvider>
      <TooltipPrimitive.Provider delayDuration={200}>
        <DirectionProvider dir="ltr">
          <ToastProvider>
            <ApolloProvider client={apolloClient}>
              <SessionStatusContextProvider>
                <ErrorCollectorContextProvider>
                  <StripeElementsProvider>
                    <SessionUserProvider>
                      <ProductAnalyticsContextProvider>
                        <RouterProvider router={router} />
                      </ProductAnalyticsContextProvider>
                    </SessionUserProvider>
                  </StripeElementsProvider>
                </ErrorCollectorContextProvider>
              </SessionStatusContextProvider>
            </ApolloProvider>
          </ToastProvider>
        </DirectionProvider>
      </TooltipPrimitive.Provider>
    </ThemeProvider>
  </ErrorBoundary>
);
