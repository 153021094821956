import { useMutation } from '@apollo/client';
import { Text } from '@radix-ui/themes';
import { componentClassName } from '@wirechunk/lib/mixer/component-class-name.ts';
import type { ComponentSpec } from '@wirechunk/lib/mixer/types/components.ts';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import type { FunctionComponent } from 'react';
import { Fragment, useState } from 'react';
import { useOptionalCurrentUser } from '../../../contexts/current-user-context.tsx';
import { useToast } from '../../../contexts/ToastContext.tsx';
import { useErrorHandler } from '../../../hooks/useErrorHandler.tsx';
import { FormField } from '../../form-field/form-field.tsx';
import { Label } from '../../label/label.tsx';
import { Spinner } from '../../spinner/spinner.tsx';
import { ChangePasswordDocument } from './mutations.generated.ts';

export const UserProfile: FunctionComponent<ComponentSpec<'UserProfile'>> = (props) => {
  const { toastSuccess } = useToast();
  const { onError, clearMessages, ErrorMessage } = useErrorHandler();
  const { user } = useOptionalCurrentUser();
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword1, setNewPassword1] = useState('');
  const [newPassword2, setNewPassword2] = useState('');

  const [changePassword, { loading: changePasswordLoading }] = useMutation(ChangePasswordDocument, {
    onError,
  });

  const onSubmit = () => {
    clearMessages();
    if (!currentPassword) {
      onError('Please enter your current password.');
      return;
    }
    if (newPassword1 !== newPassword2) {
      onError('The new passwords you entered do not match.');
      return;
    }
    void changePassword({
      variables: {
        currentPassword,
        newPassword: newPassword1,
      },
      onCompleted: () => {
        toastSuccess('Your password has been changed.');
        setCurrentPassword('');
        setNewPassword1('');
        setNewPassword2('');
      },
    });
  };

  return (
    <div className={componentClassName(props)}>
      <ErrorMessage />
      {changePasswordLoading ? (
        <Spinner pt="4" pb="2" />
      ) : user ? (
        <Fragment>
          <p>{user.firstName}, here is where you can change your password.</p>
          <FormField>
            <Label htmlFor="profileCurrentPassword">Current password</Label>
            <InputText
              id="profileCurrentPassword"
              className="w-full max-w-24rem"
              type="password"
              value={currentPassword}
              onChange={(e) => {
                setCurrentPassword(e.target.value);
              }}
            />
          </FormField>
          <FormField>
            <Label htmlFor="profileNewPassword1">New password</Label>
            <InputText
              id="profileNewPassword1"
              className="w-full max-w-24rem"
              type="password"
              value={newPassword1}
              onChange={(e) => {
                setNewPassword1(e.target.value);
              }}
            />
          </FormField>
          <FormField>
            <Label htmlFor="profileNewPassword2">Confirm new password</Label>
            <InputText
              id="profileNewPassword2"
              className="w-full max-w-24rem"
              type="password"
              value={newPassword2}
              onChange={(e) => {
                setNewPassword2(e.target.value);
              }}
            />
          </FormField>
          <Button
            label="Change password"
            className="mt-4"
            disabled={changePasswordLoading}
            onClick={onSubmit}
          />
        </Fragment>
      ) : (
        <Text>It appears you are not signed in.</Text>
      )}
    </div>
  );
};
