import {
  isValidMdy,
  mdyToLocalDate,
  mdyToWorkingPlainDate,
  plainDateToMdy,
  roundToDayEnd,
  roundToDayStart,
  toMdy,
} from '@wirechunk/lib/dates.ts';
import { componentClassName } from '@wirechunk/lib/mixer/component-class-name.ts';
import { inputLabelClassName } from '@wirechunk/lib/mixer/styles.ts';
import type { DateInputComponent } from '@wirechunk/lib/mixer/types/components.ts';
import { DateInputComponentStyle } from '@wirechunk/lib/mixer/types/components.ts';
import type { ValidInputComponent } from '@wirechunk/lib/mixer/utils.ts';
import type { WorkingPlainDate } from '@wirechunk/lib/plain-date-time.ts';
import { parsePlainDate } from '@wirechunk/lib/plain-date-time.ts';
import { stringOrDefaultNull } from '@wirechunk/lib/strings.ts';
import { clsx } from 'clsx';
import { isDate } from 'lodash-es';
import { Calendar } from 'primereact/calendar';
import type { FunctionComponent } from 'react';
import { memo, useEffect, useMemo, useState } from 'react';
import { useInputDataContext } from '../../../contexts/InputDataContext.tsx';
import { useInputId } from '../../../hooks/use-input-id.ts';
import { DateInput3Fields } from '../../date-input/date-input-3-fields.tsx';
import { InputNotice, NoticeSeverity } from '../../InputNotice/InputNotice.tsx';
import { Label } from '../../label/label.tsx';
import { withValidInputComponent } from '../../mixer-hocs/with-valid-input-component.tsx';
import styles from './DateInput.module.css';

type DateInputCalendarStyleProps = {
  inputId: string;
  inputValue: string | null;
  placeholder: string | undefined;
  valid: boolean;
  required: boolean;
  minDate: Date | undefined;
  maxDate: Date | undefined;
  onChange: (date: string | null) => void;
};

const DateInputCalendarStyle: FunctionComponent<DateInputCalendarStyleProps> = ({
  inputId,
  inputValue,
  placeholder,
  valid,
  required,
  minDate,
  maxDate,
  onChange,
}) => (
  <Calendar
    inputId={inputId}
    className={clsx('w-full', !valid && 'p-invalid')}
    panelClassName={styles.dateInputPanel}
    placeholder={placeholder || undefined}
    minDate={minDate}
    maxDate={maxDate}
    value={inputValue && isValidMdy(inputValue) ? mdyToLocalDate(inputValue) : null}
    onChange={({ value }) => {
      // Specifically check for a Date object in case we get an array of dates (for a range).
      if (isDate(value)) {
        onChange(toMdy(value));
      } else if (!required) {
        onChange(null);
      }
    }}
    todayButtonClassName="hidden"
    showButtonBar={!required}
  />
);

const MemoDateInputCalendarStyle = memo(DateInputCalendarStyle);

type DateInput3FieldsStyleProps = {
  inputId?: string;
  // A MM/DD/YYYY formatted date string.
  value: string | null;
  monthPlaceholder: string | undefined;
  dayPlaceholder: string | undefined;
  yearPlaceholder: string | undefined;
  valid: boolean;
  required: boolean;
  minDate: Date | undefined;
  maxDate: Date | undefined;
  onChange: (date: string | null) => void;
};

const DateInput3FieldsStyle: FunctionComponent<DateInput3FieldsStyleProps> = ({
  inputId,
  value,
  monthPlaceholder,
  dayPlaceholder,
  yearPlaceholder,
  valid,
  required,
  // Not implemented.
  minDate,
  // Not implemented.
  maxDate,
  onChange,
}) => {
  const [workingDate, setWorkingDate] = useState<WorkingPlainDate>(() =>
    value ? mdyToWorkingPlainDate(value) : { month: null, day: null, year: null },
  );

  useEffect(() => {
    if (value) {
      setWorkingDate(mdyToWorkingPlainDate(value));
    }
  }, [value]);

  return (
    <DateInput3Fields
      inputId={inputId}
      value={workingDate}
      monthPlaceholder={monthPlaceholder}
      dayPlaceholder={dayPlaceholder}
      yearPlaceholder={yearPlaceholder}
      valid={valid}
      showClear={!required}
      minDate={minDate}
      maxDate={maxDate}
      onChange={(value) => {
        // If we have a valid date, call the provided onChange prop with the new value. If we have no date fields
        // set, call the provided onChange prop with null. Otherwise, update local state.
        const parsedResult = parsePlainDate(value);
        if (parsedResult.success) {
          onChange(plainDateToMdy(parsedResult.data));
        } else if (!value.month && !value.day && !value.year) {
          onChange(null);
        } else {
          setWorkingDate(value);
        }
      }}
    />
  );
};

export const GuardedDateInput: FunctionComponent<ValidInputComponent<DateInputComponent>> = (
  props,
) => {
  const { getValue, setValue, getValidationError } = useInputDataContext(props);
  const inputId = useInputId(props);

  const { minimumDate, maximumDate } = props;

  const minDate = useMemo<Date | undefined>(() => {
    if (minimumDate) {
      if (minimumDate.type === 'date') {
        return new Date(minimumDate.value);
      }
      return roundToDayStart(new Date());
    }
    return undefined;
  }, [minimumDate]);
  const maxDate = useMemo<Date | undefined>(() => {
    if (maximumDate) {
      if (maximumDate.type === 'date') {
        return new Date(maximumDate.value);
      }
      return roundToDayEnd(new Date());
    }
    return undefined;
  }, [maximumDate]);

  const validationError = getValidationError(props);

  const inputValue = stringOrDefaultNull(getValue(props));

  return (
    <div className={`${componentClassName(props)} ${styles.dateInputComponent}`}>
      {props.label && (
        <Label htmlFor={inputId} className={inputLabelClassName}>
          {props.label}
        </Label>
      )}
      {props.style === DateInputComponentStyle.Calendar ? (
        <MemoDateInputCalendarStyle
          inputId={inputId}
          inputValue={inputValue}
          placeholder={props.placeholder || undefined}
          valid={!validationError}
          required={!!props.required}
          minDate={minDate}
          maxDate={maxDate}
          onChange={(date) => {
            setValue(props, date);
          }}
        />
      ) : (
        <DateInput3FieldsStyle
          inputId={inputId}
          value={inputValue}
          monthPlaceholder={props.monthPlaceholder || undefined}
          dayPlaceholder={props.dayPlaceholder || undefined}
          yearPlaceholder={props.yearPlaceholder || undefined}
          valid={!validationError}
          required={!!props.required}
          minDate={minDate}
          maxDate={maxDate}
          onChange={(date) => {
            setValue(props, date);
          }}
        />
      )}
      {validationError && (
        <InputNotice severity={NoticeSeverity.Error}>{validationError}</InputNotice>
      )}
    </div>
  );
};

export const DateInput = withValidInputComponent<DateInputComponent>(GuardedDateInput);
