import { DropdownMenu, IconButton } from '@radix-ui/themes';
import { cleanSmallId } from '@wirechunk/lib/clean-small-id.ts';
import { componentClassName } from '@wirechunk/lib/mixer/component-class-name.ts';
import type { DataInputTableComponent } from '@wirechunk/lib/mixer/types/components.ts';
import type { ValidInputComponent } from '@wirechunk/lib/mixer/utils.ts';
import { dataTableRowArrayOrDefaultEmpty } from '@wirechunk/lib/mixer/utils.ts';
import { SvgDeleteForever } from '@wirechunk/material-symbols-react-400/20/outlined/delete-forever.tsx';
import type { DataTableRow } from '@wirechunk/schemas/context-data/context-data';
import { clsx } from 'clsx';
import type { FunctionComponent } from 'react';
import { Fragment, useMemo } from 'react';
import { useInputDataContext } from '../../../contexts/InputDataContext.tsx';
import { RepeatedFormFragmentContextProvider } from '../../../contexts/repeated-form-fragment-context.tsx';
import { withValidInputComponent } from '../../mixer-hocs/with-valid-input-component.tsx';
import { RenderComponent } from '../../RenderComponent.tsx';
import styles from './DataInputTable.module.css';

const deleteButtonColumnWidth = '25px';

const GuardedDataInputTable: FunctionComponent<ValidInputComponent<DataInputTableComponent>> = (
  props,
) => {
  const inputDataContext = useInputDataContext(props);
  const { getValue, setValue } = inputDataContext;

  const rawValue = getValue(props);

  const { inputValue, inputValueWithPlaceholder, placeholderRowId } = useMemo<{
    inputValue: DataTableRow[];
    inputValueWithPlaceholder: DataTableRow[];
    placeholderRowId: string;
  }>(() => {
    const inputValue = dataTableRowArrayOrDefaultEmpty(rawValue);
    const placeholderRowId = cleanSmallId();
    return {
      inputValue,
      inputValueWithPlaceholder: [...inputValue, { id: placeholderRowId, data: {} }],
      placeholderRowId,
    };
  }, [rawValue]);

  const columnWidths =
    props.children?.map((c) => c.dataInputTableColumnOptions?.width || '1fr') || [];
  columnWidths.push(deleteButtonColumnWidth);

  const gridTemplateColumns = columnWidths.join(' ');

  return (
    <Fragment>
      <div className={componentClassName(props)}>
        <div className={styles.row} style={{ gridTemplateColumns }}>
          {props.children?.map((component, i) => (
            <div
              key={component.id}
              className={clsx(
                component.dataInputTableColumnOptions?.className,
                'flex justify-content-center align-items-center border-top-1 border-right-1 border-bottom-2 font-medium',
                i === 0 && 'border-left-1',
              )}
            >
              {component.dataInputTableColumnOptions?.header}
            </div>
          ))}
          <div />
        </div>
        {inputValueWithPlaceholder.map((row) => (
          <RepeatedFormFragmentContextProvider
            key={row.id}
            id={row.id}
            emptyPlaceholder={row.id === placeholderRowId}
            inputDataContext={inputDataContext}
            parent={props}
          >
            <div className={styles.row} style={{ gridTemplateColumns }}>
              {props.children?.map((component, i) => (
                <div
                  key={component.id}
                  className={clsx(
                    component.dataInputTableColumnOptions?.className,
                    'border-bottom-1 border-right-1',
                    i === 0 && 'border-left-1',
                  )}
                >
                  <RenderComponent {...component} />
                </div>
              ))}
              <div className="flex align-items-center pl-2">
                {!props.readonly && row.id !== placeholderRowId && (
                  <DropdownMenu.Root>
                    <DropdownMenu.Trigger>
                      <IconButton
                        type="button"
                        variant="ghost"
                        color="red"
                        className={`${styles.onlyOnRowHover} p-1`}
                      >
                        <SvgDeleteForever />
                      </IconButton>
                    </DropdownMenu.Trigger>
                    <DropdownMenu.Content>
                      <DropdownMenu.Item
                        onSelect={() => {
                          setValue(
                            props,
                            inputValue.filter(({ id }) => id !== row.id),
                          );
                        }}
                      >
                        Confirm delete
                      </DropdownMenu.Item>
                    </DropdownMenu.Content>
                  </DropdownMenu.Root>
                )}
              </div>
            </div>
          </RepeatedFormFragmentContextProvider>
        ))}
      </div>
    </Fragment>
  );
};

export const DataInputTable =
  withValidInputComponent<DataInputTableComponent>(GuardedDataInputTable);
