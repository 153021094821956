import {
  CurrentTimeKey,
  InputChangeEventValueKey,
  EventSourceComponentNameKey,
  EventSourceComponentTypeKey,
  PropsKey,
  UserEmailKey,
  UserProductItemsKey,
  UserRoleKey,
} from '@wirechunk/lib/expression-builder/evaluator.ts';
import type { ComponentType } from '@wirechunk/lib/mixer/types/components.ts';
import type { DataValue, ContextData } from '@wirechunk/schemas/context-data/context-data';
import type { CurrentUser } from '../../contexts/current-user-context.tsx';

export type MixerContextData = ContextData & {
  [CurrentTimeKey]: number;
  [EventSourceComponentNameKey]: string | null | undefined;
  [EventSourceComponentTypeKey]: string | undefined;
  [InputChangeEventValueKey]: DataValue | undefined;
  [PropsKey]: ContextData | undefined;
  // The following user properties (user email, user product items, etc.) will be set if a user is signed in.
  [UserEmailKey]: string | undefined;
  [UserProductItemsKey]: string[] | undefined;
  [UserRoleKey]: string | undefined;
};

export const buildContextData = ({
  // Destructure to closely control which fields are included in the context data.
  eventSourceComponentName,
  eventSourceComponentType,
  inputChangeEventValue,
  props,
  user,
  inputData,
}: {
  eventSourceComponentName?: string | null | undefined;
  eventSourceComponentType?: ComponentType | undefined;
  inputChangeEventValue?: DataValue | undefined;
  props?: ContextData;
  user: CurrentUser | null;
  inputData?: ContextData;
}): MixerContextData => ({
  [CurrentTimeKey]: Date.now(),
  [EventSourceComponentNameKey]: eventSourceComponentName,
  [EventSourceComponentTypeKey]: eventSourceComponentType,
  [InputChangeEventValueKey]: inputChangeEventValue,
  [PropsKey]: props,
  [UserEmailKey]: user?.email,
  [UserProductItemsKey]: user?.productItems,
  [UserRoleKey]: user?.role,
  ...inputData,
});
