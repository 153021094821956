
      export interface PossibleTypesResultData {
        possibleTypes: {
          [key: string]: string[]
        }
      }
      const result: PossibleTypesResultData = {
  "possibleTypes": {
    "ApplyCustomPromoCodeResult": [
      "ApplyCustomPromoCodeSuccessResult",
      "GenericInternalError"
    ],
    "ArchiveComponentResult": [
      "ArchiveComponentSuccessResult",
      "GenericUserError"
    ],
    "CourseVersionOrLessonVersion": [
      "CourseVersion",
      "LessonVersion"
    ],
    "CreateApiTokenResult": [
      "CreateApiTokenSuccessResult",
      "GenericUserError"
    ],
    "CreateComponentResult": [
      "CreateComponentSuccessResult",
      "GenericUserError"
    ],
    "CreateCourseResult": [
      "CreateCourseSuccessResult",
      "GenericUserError"
    ],
    "CreateExtensionResult": [
      "CreateExtensionSuccessResult",
      "GenericInternalError",
      "GenericUserError"
    ],
    "CreateExtensionVersionResult": [
      "CreateExtensionVersionSuccessResult",
      "GenericInternalError",
      "GenericUserError"
    ],
    "CreateLayoutResult": [
      "CreateLayoutSuccessResult",
      "GenericUserError"
    ],
    "CreateLayoutTemplateResult": [
      "AuthorizationError",
      "CreateLayoutTemplateSuccessResult",
      "GenericUserError"
    ],
    "CreateLessonResult": [
      "CreateLessonSuccessResult",
      "GenericUserError"
    ],
    "CreateLessonUserResult": [
      "CreateLessonUserSuccessResult",
      "GenericUserError"
    ],
    "CreateProductResult": [
      "CreateProductSuccessResult",
      "GenericUserError"
    ],
    "CreateSequenceResult": [
      "CreateSequenceSuccessResult",
      "GenericUserError"
    ],
    "CreateSiteResult": [
      "AuthorizationError",
      "CreateSiteSuccessResult",
      "GenericInternalError",
      "GenericUserError"
    ],
    "CreateSiteTemplateLayoutTemplatesResult": [
      "CreateSiteTemplateLayoutTemplatesSuccessResult",
      "GenericUserError"
    ],
    "CreateSiteTemplatePageTemplatesResult": [
      "CreateSiteTemplatePageTemplatesSuccessResult",
      "GenericUserError"
    ],
    "CreateSiteTemplateResult": [
      "CreateSiteTemplateSuccessResult",
      "GenericUserError"
    ],
    "DeleteSiteTemplateLayoutTemplatesResult": [
      "DeleteSiteTemplateLayoutTemplatesSuccessResult",
      "GenericUserError"
    ],
    "DeleteSiteTemplatePageTemplatesResult": [
      "DeleteSiteTemplatePageTemplatesSuccessResult",
      "GenericUserError"
    ],
    "DuplicateComponentResult": [
      "DuplicateComponentSuccessResult",
      "GenericInternalError",
      "GenericUserError"
    ],
    "DuplicateCourseResult": [
      "DuplicateCourseSuccessResult",
      "GenericUserError"
    ],
    "DuplicateLessonResult": [
      "DuplicateLessonSuccessResult",
      "GenericUserError"
    ],
    "DuplicateSequenceResult": [
      "DuplicateSequenceSuccessResult",
      "GenericUserError"
    ],
    "EditComponentResult": [
      "EditComponentSuccessResult",
      "GenericUserError"
    ],
    "EditCourseResult": [
      "EditCourseSuccessResult",
      "GenericUserError"
    ],
    "EditCustomTableResult": [
      "EditCustomTableSuccessResult",
      "GenericUserError"
    ],
    "EditExtensionResult": [
      "AuthorizationError",
      "EditExtensionSuccessResult",
      "GenericUserError"
    ],
    "EditFormStepPositionResult": [
      "AuthorizationError",
      "EditFormStepPositionSuccessResult",
      "GenericInternalError",
      "GenericUserError"
    ],
    "EditFormTemplateEmailTemplateResult": [
      "EditFormTemplateEmailTemplateSuccessResult",
      "GenericUserError"
    ],
    "EditFormTemplateFormattedDataTemplateResult": [
      "EditFormTemplateFormattedDataTemplateSuccessResult",
      "GenericUserError"
    ],
    "EditLayoutResult": [
      "EditLayoutSuccessResult",
      "GenericUserError"
    ],
    "EditLayoutTemplateResult": [
      "AuthorizationError",
      "EditLayoutTemplateSuccessResult",
      "GenericUserError"
    ],
    "EditLessonResult": [
      "EditLessonSuccessResult",
      "GenericUserError"
    ],
    "EditLessonUserResult": [
      "EditLessonUserSuccessResult",
      "GenericUserError"
    ],
    "EditOrgPrimaryUserResult": [
      "EditOrgPrimaryUserSuccessResult",
      "GenericUserError"
    ],
    "EditPageResult": [
      "AuthorizationError",
      "EditPageSuccessResult",
      "GenericUserError"
    ],
    "EditPageTemplateResult": [
      "AuthorizationError",
      "EditPageTemplateSuccessResult",
      "GenericUserError"
    ],
    "EditPlatformResult": [
      "EditPlatformSuccessResult",
      "GenericUserError"
    ],
    "EditProductResult": [
      "EditProductSuccessResult",
      "GenericUserError"
    ],
    "EditSequenceResult": [
      "EditSequenceSuccessResult",
      "GenericUserError"
    ],
    "EditSequenceUserResult": [
      "EditSequenceUserSuccessResult",
      "GenericUserError"
    ],
    "EditSiteResult": [
      "AuthorizationError",
      "EditSiteSuccessResult",
      "GenericInternalError",
      "GenericUserError"
    ],
    "EditUserResult": [
      "EditUserSuccessResult",
      "GenericUserError"
    ],
    "Error": [
      "AuthorizationError",
      "GenericInternalError",
      "GenericUserError"
    ],
    "FindOrCreateStripeSubscriptionResult": [
      "FindOrCreateStripeSubscriptionActiveResult",
      "FindOrCreateStripeSubscriptionErrorResult",
      "FindOrCreateStripeSubscriptionIncompleteResult",
      "FindOrCreateStripeSubscriptionUpdatedResult"
    ],
    "InvalidateApiTokenResult": [
      "GenericUserError",
      "InvalidateApiTokenSuccessResult"
    ],
    "ListResult": [
      "ActiveCampaignApiTagsList",
      "ApiTokensList",
      "ComponentsList",
      "CourseAndLessonLocationVersionsList",
      "CourseVersionsList",
      "CoursesList",
      "CustomFieldValuesList",
      "CustomFieldVersionsList",
      "CustomFieldsList",
      "CustomTableVersionFieldsList",
      "CustomTableVersionsList",
      "CustomTablesList",
      "DocumentsList",
      "ExtensionVersionsList",
      "ExtensionsList",
      "FormEntriesList",
      "LayoutTemplatesList",
      "LayoutsList",
      "LessonVersionsList",
      "LessonsList",
      "OrganizationsList",
      "PageTemplatesList",
      "PageVersionsList",
      "PagesList",
      "ProductItemPicklist",
      "ProductsList",
      "PromoCodePicklist",
      "PublicSitePagesList",
      "ReviewsList",
      "SequencesList",
      "SiteTemplatesList",
      "SitesList",
      "SubscriptionAuditLogsList",
      "SubscriptionsList",
      "UserAuditLogsList",
      "UserCourseProgressLessonsList",
      "UserCourseProgressesList",
      "UsersList"
    ],
    "MoveUserToOrgResult": [
      "GenericUserError",
      "MoveUserToOrgSuccessResult"
    ],
    "PreviewStripeUpcomingInvoiceResult": [
      "PreviewStripeUpcomingInvoiceErrorResult",
      "PreviewStripeUpcomingInvoiceSuccessResult"
    ],
    "RestoreCourseVersionChangesResult": [
      "AuthorizationError",
      "GenericInternalError",
      "GenericUserError",
      "RestoreCourseVersionChangesSuccessResult"
    ],
    "RestoreLessonVersionChangesResult": [
      "AuthorizationError",
      "GenericInternalError",
      "GenericUserError",
      "RestoreLessonVersionChangesSuccessResult"
    ],
    "RestorePageVersionChangesResult": [
      "AuthorizationError",
      "GenericInternalError",
      "GenericUserError",
      "RestorePageVersionChangesSuccessResult"
    ],
    "SplitUserToNewOrgResult": [
      "GenericUserError",
      "SplitUserToNewOrgSuccessResult"
    ],
    "SyncLayoutTemplateToLayoutsResult": [
      "AuthorizationError",
      "GenericUserError",
      "SyncLayoutTemplateToLayoutsSuccessResult"
    ],
    "SyncPageTemplateToPagesResult": [
      "AuthorizationError",
      "GenericUserError",
      "SyncPageTemplateToPagesSuccessResult"
    ],
    "UploadFileResult": [
      "AuthorizationError",
      "GenericInternalError",
      "GenericUserError",
      "UploadFileSuccessResult"
    ]
  }
};
      export default result;
    
