import { Box, type BoxProps } from '@radix-ui/themes';
import type { LayoutProps, MarginProps } from '@radix-ui/themes/props';
import { clsx } from 'clsx';
import type { FunctionComponent, PropsWithChildren } from 'react';
import styles from './form-field.module.css';

// Note className is added to the default class name.
type FormFieldProps = PropsWithChildren<
  LayoutProps & MarginProps & Pick<BoxProps, 'className' | 'display' | 'asChild'>
>;

export const FormField: FunctionComponent<FormFieldProps> = ({ children, className, ...props }) => (
  <Box {...props} className={clsx(styles.formField, className)}>
    {children}
  </Box>
);
