import { useQuery } from '@apollo/client';
import type { ErrorHandler } from '../useErrorHandler.tsx';
import type { MinimalFormsQuery } from './queries.generated.ts';
import { MinimalFormsDocument } from './queries.generated.ts';

export type Form = MinimalFormsQuery['forms'][number];

type FormsMinimal = {
  forms: Form[] | null;
  loading: boolean;
};

export const useFormsMinimal = (siteId: string, onError: ErrorHandler['onError']): FormsMinimal => {
  const { data, loading } = useQuery(MinimalFormsDocument, {
    onError,
    fetchPolicy: 'cache-and-network',
    variables: {
      siteId,
    },
  });

  return {
    forms: data?.forms || null,
    loading,
  };
};
